import { DocsForSaleFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const docsForSaleFilterMessages = defineMessages<DocsForSaleFilterEnum>({
    [DocsForSaleFilterEnum.Section]: { defaultMessage: 'IDFA Program section' },
    [DocsForSaleFilterEnum.CountriesOfProduction]: { defaultMessage: 'Country of Production' },
    [DocsForSaleFilterEnum.LengthInMinutes]: { defaultMessage: 'Duration' },
    [DocsForSaleFilterEnum.Accessibility]: { defaultMessage: 'Accessibility' },
    [DocsForSaleFilterEnum.SalesAgents]: { defaultMessage: 'Sales Agent' },
    [DocsForSaleFilterEnum.DfsSubject]: { defaultMessage: 'Topics' },
});

export default docsForSaleFilterMessages;
