import { useMemo } from 'react';
import { SearchState, SEARCH_RESULTS_AMOUNT } from 'shared';

import { DocsForSaleFilterQueryParams } from '../components/pages/DocsForSaleCollectionPage';
import docsForSaleFilterMessages from '../constants/docsForSaleFilterMessages';
import { DocsForSaleFilterEnum, useSearchDocsForSaleFiltersQuery, useSearchDocsForSaleHitsQuery } from '../gql/api';

const useDocsForSaleSearch = (searchState: SearchState<typeof DocsForSaleFilterQueryParams>) => {
    const { debouncedQuery, queryFilters, page } = searchState;
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const hitsResult = useSearchDocsForSaleHitsQuery(
        {
            query: debouncedQuery,
            filters: (Object.entries(queryFilters) as [DocsForSaleFilterEnum, string[]][])
                .filter(([, value]) => value.length > 0) // remove empty filters
                .map(([key, value]) => ({
                    key,
                    value,
                })),
            offset,
            limit: SEARCH_RESULTS_AMOUNT,
        },
        { keepPreviousData: true }
    );
    const filtersResult = useSearchDocsForSaleFiltersQuery(
        {
            filters: [],
        },
        { keepPreviousData: true }
    );

    const totalPages = useMemo(
        () => Math.ceil((hitsResult.data?.searchDocsForSale?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT),
        [hitsResult.data?.searchDocsForSale?.totalHits]
    );

    const totalHits = useMemo(
        () => hitsResult.data?.searchDocsForSale?.totalHits,
        [hitsResult.data?.searchDocsForSale?.totalHits]
    );

    const filters = useMemo(
        () =>
            filtersResult.data?.searchDocsForSale?.filters
                .sort(
                    (a, b) =>
                        Object.keys(docsForSaleFilterMessages).indexOf(a.filter) -
                        Object.keys(docsForSaleFilterMessages).indexOf(b.filter)
                )
                .map(filter => ({
                    ...filter,
                    amounts: filter.amounts
                        .filter(amount => amount.key.indexOf('Not selected at IDFA') < 0)
                        .map(amount => (amount.key === 'Variable' ? { ...amount, key: 'Series' } : amount)),
                })),
        [filtersResult.data?.searchDocsForSale?.filters]
    );

    return {
        totalPages,
        totalHits,
        filters,
        offset,
        hits: hitsResult.data?.searchDocsForSale?.hits,
        isError: hitsResult.isError || filtersResult.isError,
        isFetching: hitsResult.isFetching || filtersResult.isFetching,
    };
};

export default useDocsForSaleSearch;
