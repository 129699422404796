import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo, useState } from 'react';
import {
    DocsForSaleFilterEnum,
    SearchDocsForSaleFiltersQueryVariables,
    SearchDocsForSaleHitsQueryVariables,
    useSearchDocsForSaleFiltersQuery,
    useSearchDocsForSaleHitsQuery,
    useSearchDocsForSaleViewingHistoryHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useDocsForSaleSearch from '../../hooks/useDocsForSaleSearch ';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    getMetaForViewingHistory,
    SEARCH_RESULTS_AMOUNT,
    useSearchState,
    useUserRoles,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import docsForSaleFilterMessages from '../../constants/docsForSaleFilterMessages';
import ProfessionalsBreadcrumbs from '../ProfessionalsBreadcrumbs';
import { Toggle } from 'designsystem';
import { FormattedMessage } from 'react-intl';

type DocsForSaleCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
>;

export const DocsForSaleFilterQueryParams: Record<DocsForSaleFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(DocsForSaleFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const DocsForSaleCollectionPage: FC<{ data: DocsForSaleCollectionContentPage }> = ({ data }) => {
    const [state, setState] = useState<'collection' | 'viewingHistory'>('collection');
    const { hasDocsForSaleAccess } = useUserRoles();

    // collection
    const searchState = useSearchState(DocsForSaleFilterQueryParams);
    const { hits, ...docsForSaleSearch } = useDocsForSaleSearch(searchState);
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.intro?.translation,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'docsForSale' }),
            })),
        [hits]
    );

    // viewing history
    const { data: viewingHistoryData, ...viewingHistorySearch } = useSearchDocsForSaleViewingHistoryHitsQuery(
        {},
        {
            keepPreviousData: true,
            enabled: state === 'viewingHistory',
        }
    );
    const viewingHistoryHits = viewingHistoryData?.viewer?.docsForSaleViewingHistory;
    const processedViewingHistoryHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (viewingHistoryHits || []).map(hit => ({
                id: hit.film.id,
                title: hit.film.fullPreferredTitle,
                image: hit.film.publications.favoriteImage,
                description: hit.film.intro?.translation,
                meta: getMetaForViewingHistory(hit),
            })),
        [viewingHistoryHits]
    );

    const collectionProps: ComponentProps<typeof CollectionOverviewTemplate> = useMemo(
        () => ({
            collectionPath: 'docs-for-sale',
            breadcrumbs: <ProfessionalsBreadcrumbs />,
            pageTitle: data.title,
            pageIntroText: data.introText,
            pageOpenGraphImages: cmsImageToOpenGraph(data.navThumbnail),

            ...(state === 'collection'
                ? {
                      queryParams: DocsForSaleFilterQueryParams,
                      filterMessages: docsForSaleFilterMessages,
                      hits: processedHits,
                      ...docsForSaleSearch,
                  }
                : {
                      hits: processedViewingHistoryHits,
                      totalHits: processedViewingHistoryHits.length,
                      ...viewingHistorySearch,
                  }),
        }),
        [
            data.introText,
            data.navThumbnail,
            data.title,
            docsForSaleSearch,
            processedHits,
            processedViewingHistoryHits,
            state,
            viewingHistorySearch,
        ]
    );

    return (
        <CollectionOverviewTemplate
            {...collectionProps}
            toggle={
                hasDocsForSaleAccess && (
                    <Toggle
                        options={[
                            {
                                id: 'entire-collection',
                                children: <FormattedMessage defaultMessage="Entire collection" />,
                                onClick: () => setState('collection'),
                                selected: true,
                            },
                            {
                                id: 'viewing-history',
                                children: <FormattedMessage defaultMessage="My viewed films" />,
                                onClick: () => setState('viewingHistory'),
                                selected: state === 'viewingHistory',
                            },
                        ]}
                    />
                )
            }
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchDocsForSaleHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchDocsForSaleHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocsForSaleHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchDocsForSaleFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchDocsForSaleFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocsForSaleFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default DocsForSaleCollectionPage;
